import React from "react"
import Layout from "../components/layout"
import SEO from "../components/seo"
import ArticlePreview from "../components/articlePreview"
import { Row, Col, Container, Button } from "react-bootstrap"

const SearchPage = () => {
    const [query, setQuery] = React.useState("")
    const [results, setResults] = React.useState([])
    const [searched, setSearched] = React.useState(false)

    const handleFilter = event => {
        var f = event.target.value.toLowerCase()
        setQuery(f);
    }

    const doSearch = () => {
            const f = query + " "
            console.log(f)

            var index = window.__FLEXSEARCH__.en.index
            var store = window.__FLEXSEARCH__.en.store
            if (!f || !index) {
                setResults([])
            } else {
                var results = []
                // search the indexed fields
                Object.keys(index).forEach(idx => {
                    results.push(...index[idx].values.search(
                        {
                            query: query,
                            limit: 15,
                            bool: "or"
                        }
                    )) // more search options at https://github.com/nextapps-de/flexsearch#index.search
                })
                console.log(results)

                // find the unique ids of the nodes
                results = Array.from(new Set(results))

                console.log(results)
                // return the corresponding nodes in the store
                var nodes = store
                    .filter(node => (results.includes(node.id) ? node : null))
                    .map(node => node.node)
                console.log(nodes)
                setResults(nodes)
            }
        

    }

    return (
        <Layout pageInfo={{ pageName: "search" }}>
            <SEO title="Search" />
            <Row >
                <Col>
                    <Container className="mt-4 ">
                        <h2>Search:</h2>
                        <Row>
                            <Col className="col-md-3 col-xs-2">
                                <input style={{marginTop:"5px"}} type="text" value={query} onChange={handleFilter} placeholder={'Search'}>
                                </input>
                            </Col>
                            <Col className="col-md-3 col-xs-2">
                                <Button onClick={doSearch} variant="primary">Search</Button>
                            </Col>
                        </Row>

                        <hr></hr>

                        {results.length > 0 &&
                            <div className="text-center">
                                <h4>Showing results for {query}:</h4>
                            </div>
                        }
                        {(results.length == 0 && !searched) &&
                            <div className="text-center">
                                <h6 >Type in your search in the input above and then click search.</h6>
                            </div>
                        }
                        {(results.length == 0 && searched) &&
                            <div className="text-center">
                                <h6 >No results for {query}.</h6>
                            </div>
                        }
                        {results.map(row => (
                            <ArticlePreview
                                key={row.url}
                                title={row.title}
                                date={""}
                                excerpt={row.url}
                                slug={row.url}
                            ></ArticlePreview>
                        ))}

                    </Container>
                </Col>
            </Row>
        </Layout>
    )
}
export default SearchPage
